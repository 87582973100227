.show_dlt {
    position: relative;;
}

.hide_dlt {
max-width: 0;
overflow: hidden;
position: absolute;
}

.table_row_dlt:hover .hide_dlt{
display: flex;
justify-content: center;
align-items: center;
position: absolute;
left: 0;
max-width: 1000px;
top: 0;
min-width: 100%;
height: 100%;
padding: 2px !important;
transition: max-width 0.2s ease-in;
background-color: gray;
font-size: 16px !important;
cursor: pointer;
}
