.passcode-popup {
  background-color: white;
  position: absolute;
  left: calc( 50% - 185px );
  right: 5rem;
  top: 3rem;
  max-width: 400px;
  border-radius: 1rem;
  padding: 1rem;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-transform: none !important;
  z-index: 100000;
}
.overlay-bg{
  background: rgb(0 0 0 / 49%);
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10000;
}

/* .submitted-main .overlay-bg{
  background: rgb(0 0 0 / 6%)  !important;
} */
.passcode-popup.right {
  left: unset;
}
.passcode-popup > form {
  display: flex;
  flex-direction: column;
  row-gap: .5rem;
}
.passcode-popup label {
  text-align: left;
  font-size: .9rem;
  color: var(--bs-gray);
}
.passcode-popup input {
  border-radius: .5rem;
  padding: .5rem;
}
.passcode-popup button {
  background-color: var(--bs-primary);
  margin-top: .5rem;
  border-radius: 2rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-weight: 700;
  font-size: 1rem;
  color: #fff;
  border: none;
  box-shadow: none;
}
.passcode-popup .error-container {
  font-size: 0.9rem;
}