.green:hover{
    background-color: #3dc4a0;
    color: white
  }
  
  .bg-grid{
    background-color: #edf2f1;
  }

  .btn-cancel{
    background-color:#898989;
    color:#fff !important;
  }


/* .modal-content{
  max-height: 80vh;
} */

/* .modal-dialog {
  max-width: 80%; 
  position: fixed; 
  margin-left: 280px;
  margin-top: 80px;
} */

.modal-body {
  max-height: auto; 
}  

  
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #3dc4a0;
  }

  
  .sublist--menu-navper {
    
    max-height: calc( 100vh - 24rem );
}

.minus{
  position: absolute;
  top: 30px;
  right: 165px;
}

.plus{
  position: absolute;
  top: 30px;
  left: 165px;
  width: 10px;
  
}


/* .modal-right {
height: 650px;
} */

.count{
  position: absolute;
  left: 90px;
  bottom: -12px;
  color: #9ad7ab;
  border: solid #3ed1b3;
  border-width: thin;
  font-size: small;
}  


.counter-card:hover .count{
   color: #0a8d6e;
}

/* card inside item modal */
.item-property-card {
  border-radius: 0.75rem;
  position: relative;
}

/* .form-control{
  border-radius: 0.50rem;
} */

.input-box {
  border-radius: 0.50rem;
  font-size: small;
  background-color: #eeeeee;
}

.item-property-card-selected {
  background-color: rgb(39, 204, 170);
  color: #fff;
}

/* sidebar item inside item modal  */
.property-group {
  /* styles */
  text-align: left;
  font-size: 1rem;
}
.property-group-selected {
  background-color: #27ccaa !important;
  color: #fff !important;
  border-color: #27ccaa;
}
.order-type-selected{
  background-color: #27ccaa !important;
  color: #fff !important;
  border-color: #27ccaa;
  border: 2px solid #27ccaa;
  letter-spacing: 0.8px;
}
.order-type{
  border: 2px solid #0a8d6e !important;
  background: transparent;
  border-color: #0a8d6e;
  letter-spacing: 0.8px;
}
.party-selected{
  background-color: #0a8d6e !important;
  color: #fff !important;
  border-color: #0a8d6e;
  border: 2px solid #0a8d6e;
  letter-spacing: 0.8px;
}
.party{
  border: 2px solid #27ccaa !important;
  background: transparent;
  border-color: #27ccaa;
  letter-spacing: 0.8px;
}
.required{
  color: rgb(167, 14, 14);
  margin: 0 0.2rem;
}

.list--menu-nav{
  height: calc(100vh - 120px);
}

@media only screen 
and (max-width: 1180px) { 

.list--menu-nav{
  height: calc(100vh - 80px);
}

}


@media only screen 
and (max-width: 1024px) { 
  
  .list--menu-nav{
    height: calc(100vh - 85px);
  }
  
}

.list--menu-nav::-webkit-scrollbar-track {
  background-color: transparent;

}
.list--menu-nav::-webkit-scrollbar{
  background-color: transparent;
  width: 0.5rem;
  
}
.list--menu-nav::-webkit-scrollbar-thumb{
    visibility: hidden;
    background-color:  rgba(20, 20, 20, 0.3);
    border-radius: 1rem;
}
.list--menu-nav:hover::-webkit-scrollbar-thumb,
.list--menu-nav:active::-webkit-scrollbar-thumb,
.list--menu-nav:focus::-webkit-scrollbar-thumb
 {
  background-color:  rgba(20, 20, 20, 0.3);
  border-radius: 1rem;
  visibility: visible;
 }
